<template>
  <div>
    <b-row>
      <b-col md="6">
        <!-- draggable -->
        <draggable
          :list="tasks"
          tag="ul"
          group="people"
          class="list-group list-group-flush cursor-move"
        >
          <b-list-group-item
            v-for="(listItem, index) in tasks"
            :key="index"
            tag="li"
          >
            <div class="d-flex">
              <div class="ml-25">
                <h4>
                  <b>{{index + 1}} -</b> {{ listItem.name }}
                </h4>
                <small class="align-items-end ml-4">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    class="btn-icon rounded-circle"
                    @click="openEditTaskModal(index)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="btn-icon rounded-circle ml-1"
                    @click="deleteTask(listItem.id)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </small>
              </div>
            </div>
          </b-list-group-item>
        </draggable>
      </b-col>
      <!-- BUTTONS -->
      <b-col md="6" class="mt-sm-2 mt-md-0">
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            block
            v-b-modal.modal-success
          >
            <feather-icon icon="PlusCircleIcon" class="mr-50" />
            <span class="align-middle">Nova Tarefa</span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <!-- modal new task -->
    <b-modal
      id="modal-success"
      ok-only
      ok-title="Adicionar"
      modal-class="modal-primary"
      centered
      title="Nova tarefa"
      @ok="adicionar()"
    >
      <b-card-text>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Nome"
          label-for="input-lg"
        >
          <b-form-input id="input-lg" size="lg" v-model="newTask.name" />
        </b-form-group>
      </b-card-text>
    </b-modal>
    <!-- modal edit task -->
    <b-modal
      ref="modal-edit"
      ok-only
      ok-title="Adicionar"
      modal-class="modal-primary"
      centered
      title="Nova tarefa"
      @ok="editTask()"
    >
      <b-card-text>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Nome"
          label-for="input-lg"
        >
          <b-form-input id="input-lg" size="lg" v-model="TaskEdited.name" />
        </b-form-group>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";

import {
  BListGroupItem,
  BAvatar,
  BRow,
  BCol,
  BCard,
  BButton,
  BModal,
  VBModal,
  BCardText,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BAvatar,
    BListGroupItem,
    BRow,
    BCol,
    BModal,
    VBModal,
    draggable,
    BCard,
    BButton,
    BCardText,
    Ripple,
    BFormGroup,
    BFormInput,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      tasks: [],
      newTask: {
        id: 0,
        name: "",
      },
      TaskEdited: {
        id: "",
        name: "",
      },
    };
  },
  methods: {
    async getTask() {
      await axios
        .post(`${baseApiUrl}/config`, { name: "tasks" })
        .then((res) => {
          if (!res.data.success) return;
          this.tasks = res.data.data;
          this.save = false;
        });
    },
    adicionar() {
      if (!this.newTask.name) return;
      (this.newTask.id = Math.floor(new Date() / 1000)),
        this.tasks.push(this.newTask);
      this.newTask = {
        id: "",
        name: "",
      };
      this.saveTask();
    },
    async saveTask() {
      await axios.put(`${baseApiUrl}/config`, this.tasks).then((res) => {
        if (!res.data.success) return;
      });
    },
    openEditTaskModal(id) {
      this.TaskEdited.id = id;
      this.$refs["modal-edit"].show();
    },
    editTask() {
      this.tasks[this.TaskEdited.id].name = this.TaskEdited.name;
      this.saveTask();
      this.TaskEdited = {
        id: "",
        name: "",
      };
    },
    deleteTask(id) {
      this.$bvModal
        .msgBoxConfirm(`Confirma a aprovação de `, {
          title: "Aprovação",
          size: "sm",
          okVariant: "primary",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-danger",
          hideHeaderClose: false,
          centered: true,
        })
        .then((res) => {
          if (!res) return;
          this.tasks = this.tasks.filter(function (task) {
            return task.id !== id;
          });
        });
    },
  },
  watch: {
    tasks: function (val) {
      this.saveTask();
    },
  },
  mounted() {
    this.getTask();
  },
};
</script>