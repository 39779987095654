<template>
  <div>
    <b-alert variant="danger" show> Não funciona </b-alert>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Geral</span>
        </template>
        <General v-if="options.user" :general-data="options.user" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="CompassIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Conecxões</span>
        </template>
        <Conection />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";

import General from "./General.vue";
import Tarefas from "./Tarefas.vue";
import Conection from "./Conection.vue";
export default {
  computed: mapState(["user"]),
  components: {
    General,
    Tarefas,
    Conection,
  },
  data() {
    return {
      options: {},
    };
  },
  mounted() {
    this.options = this.user;
  },
};
</script>

<style>
</style>