<template>
  <div>
    <div>
      <b-card>
        <div v-if="whatsapp.connected">
          <b-row>
            <b-spinner variant="success" type="grow" class="m-2" />
            <h2 class="my-2 green">Whatsapp Online</h2>
          </b-row>
          <b-row>
            <!-- <b-button variant="danger" @click="desconectar()">
              Desconectar
            </b-button> -->
          </b-row>
        </div>
        <div v-else>
          <b-row>
            <b-spinner variant="danger" type="grow" class="m-2" />
            <h2 class="my-2 green">Offline</h2>
          </b-row>
          <b-row>
            <b-button
              variant="primary"
              @click="conectar()"
              v-if="!whatsapp.connected"
            >
              Conectar
            </b-button>
          </b-row>
        </div>
      </b-card>
    </div>
    <div v-if="qr">
      <b-card class="text-center">
        <qrcode-vue :value="value" :size="size" level="H" />
      </b-card>
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import axios from "axios";
import { baseApiUrl } from "@/global";

export default {
  data() {
    return {
      whatsapp: null,
      value: "",
      size: 200,
      qr: false,
    };
  },
  components: {
    QrcodeVue,
  },
  methods: {
    getStats() {
      axios.get(`${baseApiUrl}/status`).then((res) => {
        console.log(res.data);
        this.whatsapp = res.data.whatsapp;
      });
    },
    dropStats() {
      axios.post(`${baseApiUrl}/wp/desconect`).then((res) => {
        console.log(res.data);
      });
    },
    conectar() {
      this.qr = true;
    },
    desconectar() {
      this.qr = false;
      this.whatsapp.connected = false
      axios.post(`${baseApiUrl}/wp/desconect`);
    },
  },
  mounted() {
    this.getStats();
  },
  sockets: {
    newQr(qr) {
      console.log("qr");
      this.value = qr;
    },
    wpConnected() {
      this.getStats();
    },
  },
};
</script>